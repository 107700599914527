import React, { useEffect, useState } from 'react';
import './styles/loginpage.css';
import logoPlaceholder from './images/KurrentLogo.png';
import Parse from 'parse/dist/parse.min.js';
import { useNavigate } from "react-router-dom";



function SignupPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [person, setPerson] = useState(null);

  const className = 'Person';
  const queryParams = encodeURIComponent(JSON.stringify({ email: email}));
  const url = `https://parseapi.back4app.com/classes/${className}?where=${queryParams}`;

  

  const handlesignup = () => {
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Parse-Application-Id': 'HD7nLDS9nVOe7nyi60WW9vX0kuZPIJtZju34z81n',
        'X-Parse-REST-API-Key': 'Of6gNG4AtnollgQ9CdtEfVJSsdyw9f4qOOMa7JWG'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.results.length > 0) {
        console.log('Email in use');
        showAlert_emailInUse();
        navigate("/");
      } else {
        console.log('Creating user');
        try {
          const Person = new Parse.Object('Person');
          Person.set('email', email);
          Person.set('username', username);
          Person.set('password', password);
          Person.set('verified', true);
          Person.set('verificationToken', makeid(10))
          Person.save();
          showAlert_signUpSuccess();
          navigate("/", {state: Person})
        } catch (error) {
          console.log('Error saving new person: ', error);
          showAlert_errorWithBackend();
        }
      }
    })
    .catch(error => {
      console.error('Error checking user data:', error);
      showAlert_errorWithBackend();
    });
  } 

  const showAlert_emailInUse = () => {
    window.alert('Email already exists');
  };

  const showAlert_signUpSuccess = () => {
    window.alert('You have signed up.');
  };

  const showAlert_errorWithBackend = () => {
    window.alert('There was an error retreiving the user data. Please refresh the page and try again!');
  };

  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

  return (
    <div className="login-page">
    <img src={logoPlaceholder} alt="Logo" className="logo" />
      <div className="login-form">
      <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value.toLowerCase())}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button onClick={handlesignup} className="signup-button">Create Account</button>
       
      </div>
    </div>
  );
}

export default SignupPage;
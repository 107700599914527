import React, { useState } from 'react';
import './styles/userprofile.css';
import { useLocation } from "react-router-dom";

function UserProfile() {

  const { state } = useLocation();

  const [username, setUsername] = useState(state.username); // Initial username
  const [profilePicture, setProfilePicture] = useState(state.profilephoto); // Initial profile picture URL
  const [newUsername, setNewUsername] = useState(''); // New username entered by the user
  const [newProfilePicture, setNewProfilePicture] = useState(null); // New profile picture file selected by the user

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  // const handleProfilePictureChange = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setNewProfilePicture(reader.result);
  //   };
  // };

  const handleSave = () => {
    if (newUsername !== '') {
      setUsername(newUsername);
    }
    if (newProfilePicture !== null) {
      setProfilePicture(newProfilePicture);
    }
    setNewUsername('');
    setNewProfilePicture(null);
  };

  return (
    <div className="profile-page">
      {/* <div className="profile-container">
        <img src={profilePicture} alt="Profile" className="profile-picture" />
        <input type="file" accept="image/*" onChange={handleProfilePictureChange} />
      </div> */}
      <div className="username-container">
        <label>Username:</label>
        <input
          type="text"
          value={newUsername}
          onChange={handleUsernameChange}
          placeholder={username}
        />
      </div>
      <div className="button-container">
        <button onClick={handleSave}>Save</button>
        <button onClick={() => window.history.back()}>Back</button>
      </div>
    </div>
  );
}

export default  UserProfile;
import React, { useState } from 'react';
import './styles/loginpage.css';
import logoPlaceholder from './images/KurrentLogo.png';
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [person, setPerson] = useState(null);
  const [showTextBox, setShowTextBox] = useState(false);
  const [condition, setCondition] = useState(false)
  const navigate = useNavigate();

  const className = 'Person';
  const queryParams = encodeURIComponent(JSON.stringify({ username: username, password: password, verified: true }));
  const url = `https://parseapi.back4app.com/classes/${className}?where=${queryParams}`;

  const handleLogin = () => {
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Parse-Application-Id': 'HD7nLDS9nVOe7nyi60WW9vX0kuZPIJtZju34z81n',
        'X-Parse-REST-API-Key': 'Of6gNG4AtnollgQ9CdtEfVJSsdyw9f4qOOMa7JWG'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.results.length) {
        console.log('User data exists:');
        navigate('/home', { state: data.results[0] });
               
      } else {
        console.log('User data does not exist');
        showAlert_noUserData();
        navigate("/")
      }
    })
    .catch(error => {
      console.error('Error checking user data:', error);
      showAlert_errorWithBackend();
    });
  };

  const showAlert_noUserData = () => {
    window.alert('Username/Passworde does not match any known user.');
  };

  const showAlert_errorWithBackend = () => {
    window.alert('There was an error retreiving the user data. Please refresh the page and try again!');
  };

  const handleSignup = () => {
    navigate("/signup")
  }
  return (
    <div className="login-page">
  <img src={logoPlaceholder} alt="Logo" className="logo" />
  <div className="login-form">
    <input
      type="text"
      placeholder="Username"
      value={username}
      onChange={(e) => setUsername(e.target.value.toLowerCase())}
      className="input-field"
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="input-field"
    />
    <button onClick={handleLogin} className="login-button">Login</button>
    <button onClick={handleSignup} className="signup-button">Sign Up</button>
  </div>
</div>
  );
}

export default LoginPage;
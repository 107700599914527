import './styles/homepage.css';
import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import logoPlaceholder from './images/KurrentLogo.png';
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useGeolocated } from "react-geolocated";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";

setDefaults({
  key: "AIzaSyBlKFVdc1kaeMkvG5zaVKoZxOAb2t5VCw4", // Your API key here.
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
});

function HomePage() {
  const { state } = useLocation();
  const [town, setTown] = useState('');
  const [username, setUsername] = useState(state.username);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  var userTown = '';
  const [showPostModal, setShowPostModal] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [city, setCity] = useState('');
  const [error, setError] = useState('');

  Parse.initialize('HD7nLDS9nVOe7nyi60WW9vX0kuZPIJtZju34z81n', 'KUQO7ultqc8XKpwpxgwyuy7hkasfAROu6FLyUgDd');
  Parse.serverURL = 'https://parseapi.back4app.com';

  
  useEffect(() => {
 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          console.log(latitude);
          console.log(longitude);
          getCityName(latitude, longitude);
        },
        error => {
          setError('Unable to retrieve your location');
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }

    const intervalId = setInterval(() => {
      fetchMessages();

    }, 5000000); 

    return () => clearInterval(intervalId); 
  }, []);


  const getCityName = async (latitude, longitude) => {
    try {
      const apiKey = '39321325a962c61a9f5c93e4f5714b89';
      const response = await axios.get(
        `http://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&limit=1&appid=${apiKey}`
      );
      const cityName = response.data[0].name;
      setCity(cityName);
      console.log(cityName);
      fetchMessages(cityName);
    } catch (err) {
      setError('Unable to retrieve city name');
    }
  };

function fetchMessages(cn) {
    console.log('starting query to find messages in: ', cn);
    const query = new Parse.Query('userPosts');
    query.equalTo("town", cn);
    query.descending("createdAt");
    query.limit(20);
    query.find().then(results => {
        const formattedMessages = results.map(result => ({
            message: result.get('message'),
            user: result.get('user')
        }));
        console.log('setting messages');
        setMessages(formattedMessages);
    }).catch(error => {
        console.error('Error fetching messages:', error);
    });
};

const showAlert_messageReported = () => {
  window.alert('Message Reported');
};

async function handlePostMessage() {

  console.log('starting message post in ', city);
  if (newMessage.trim() === '') return;

  const Message = Parse.Object.extend('userPosts');
  const message = new Message();
  message.set('message', newMessage.trim());
  message.set('town', city);
  message.set('user', username);

  message.save().then(() => {
    setNewMessage('');
    fetchMessages(city);
  }).catch(error => {
    console.error('Error posting message:', error);
  });
};

  return (
    <div className="home-page">
      <img src={logoPlaceholder} alt="Logo" className="logo" />

    <div className="user-location">
        {<p>You are Kurrently in: {city}</p>}
      </div>
      <button className="post-button" onClick={() => setShowPostModal(true)}>Post</button>
      <button className="refresh-button" onClick={() => fetchMessages(city)}>Refresh Messages</button>

{showPostModal && (
  <div className="modal">
    <div className="modal-content">
      <textarea
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        maxLength={100}
        placeholder="Type your message (max 100 characters)"
      />
      <div className="modal-buttons">
        <button onClick={handlePostMessage}>Submit</button>
        <button onClick={() => setShowPostModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}
      <div className="message-box">
        {messages.map((message, index) => (
          <button key={index} className="message">
        <p>{message.user.toString()} says: {message.message.toString()}</p>
        <button className="report-button" onClick={() => showAlert_messageReported()}>Report</button>
    </button>
  ))}
</div>

      <div className="container">
      <div className="button-container">
        <button onClick={() => setAboutOpen(true)}>About</button>
        <button onClick={() => setRulesOpen(true)}>Rules</button>
        <button onClick={() => setContactOpen(true)}>Contact</button>
      </div>

      {/* About Window */}
      {aboutOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setAboutOpen(false)}>&times;</span>
            <p>Thank you for using Kurrent. This is a new social media site developed to 
              give information in real time to a user in a specific location. This site uses your location 
              and provides a steady stream of information posted by real people in that location in real time.
              Each post has a limit of 100 characters and users can post as many posts as they want. Currently, 
              the site is in a preview release (version 1.0.0) and will be updated over time with new information
              and features. Stay tuned and check up every day to see whats happening Kurrently!
            </p>
          </div>
        </div>
      )}

      {/* Rules Window */}
      {rulesOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setRulesOpen(false)}>&times;</span>
            <p>1.) You have freedom of speech, but not freedom of consequences of said speech. Anything illegal posted will be investigated.</p>
            <p>2.) Please be respectful of others and their beliefs. You are free to disagree with others but this is not the place to argue.</p>
            <p>3.) Absolutly no posting of others personal information (Doxxing).</p>
            <p>4.) Failure to follow the rules above can result in banning.</p>
            <p>
            Legal Notice and Disclaimer

1. General Disclaimer

The content on this social media site, including text, images, and any other material, is provided for informational and entertainment purposes only. The views and opinions expressed on this site are those of the individual contributors and do not necessarily reflect the views of Kurrent. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of any content.

2. No Professional Advice

Nothing on this social media site should be considered professional advice, including but not limited to legal, financial, medical, or other forms of professional advice. Always seek the advice of a qualified professional in your jurisdiction with any questions you may have regarding any specific matter.

3. Limitation of Liability

To the fullest extent permitted by law, Kurrent shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of your access to or use of this site, including but not limited to any errors or omissions in any content or any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via this site.

4. Third-Party Content and Links

This site may contain links to third-party websites or content. These links are provided for convenience only, and Kurrent does not endorse or take responsibility for the content or practices of any third-party sites. You access any linked sites at your own risk.

5. User-Generated Content

By posting content on this site, users agree to be solely responsible for their contributions. Kurrent does not endorse any user-generated content and reserves the right to remove any content that violates the terms of use or applicable law.

6. Intellectual Property

All content on this site, unless otherwise noted, is the intellectual property of Kurrent and may not be reproduced, distributed, or transmitted in any form or by any means without the prior written permission of the copyright owner.

7. Changes to This Notice

Kurrent reserves the right to modify this legal notice at any time without prior notice. Your continued use of this site constitutes acceptance of any such changes.
            </p>
          </div>
        </div>
      )}

      {/* Contact Window */}
      {contactOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setContactOpen(false)}>&times;</span>
            <p>Please email kurrentcontact@gmail.com with anything and everything relating to this site.</p>
          </div>
        </div>
      )}
    </div>
    </div>
    
    
  );
  
}



export default HomePage;
import * as React from 'react';
import './styles/App.css';
import { Routes, Route } from 'react-router-dom';

import Login from './pages/LoginPage';
import Signup from './pages/SignUpPage';
import Home from './pages/HomePage';
import Profile from './pages/UserProfile';
import Email from './pages/SendConfirmationEmail';

import Parse from 'parse/dist/parse.min.js';


const PARSE_APPLICATION_ID = 'HD7nLDS9nVOe7nyi60WW9vX0kuZPIJtZju34z81n';
const PARSE_HOST_URL = 'https://parseapi.back4app.com/';
const PARSE_JAVASCRIPT_KEY = 'KUQO7ultqc8XKpwpxgwyuy7hkasfAROu6FLyUgDd';
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="home" element={<Home />} />
        <Route path="user" element={<Profile />} />
        <Route path="emailconfirm" element={<Email />} />
      </Routes>
    </div>
  );
}



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";


function EmailPage() {
const { state } = useLocation();
  
  const [email, setEmail] = useState(state.email);
  const [verificationToken, setVerificationToken] = useState(state.verificationToken);
  const [message, setMessage] = useState('');



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:5000/send-confirmation-email', {
        email,
        verificationToken,
      });

      setMessage('Confirmation email sent successfully!');
    } catch (error) {
      setMessage('Failed to send confirmation email.');
    }
  };

  return (
    <div>
      <h2>Send Confirmation Email</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
        </div>
        <button type="submit">Send Verification Email</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default EmailPage;